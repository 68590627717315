import './_assets/v2/css/style.min.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { g, log, socials, t } from "../../utils"
import { addDoc, collection, doc, GeoPoint, getCountFromServer, getDoc, getDocs, getFirestore, orderBy, query, serverTimestamp, updateDoc, where } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAonRA4dP7VLhJMOsyB76oMgDfJrPRuEaU",
  authDomain: "kudu-ride.firebaseapp.com",
  databaseURL: "https://kudu-ride-default-rtdb.firebaseio.com",
  projectId: "kudu-ride",
  storageBucket: "kudu-ride.appspot.com",
  messagingSenderId: "277154439302",
  appId: "1:277154439302:web:72d74f99abc8fd65313107",
  measurementId: "G-5BZF849KJ9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore = getFirestore()

const configuration = await getDoc(doc(firestore, "system/website"))

if (configuration.exists()) {
  const configurationData = configuration.data()

  g("sub_title").innerText = configurationData.hero.subtitle
  g("title").innerText = configurationData.hero.title
  g("description").innerText = configurationData.hero.description

  const action1 = configurationData.hero.action1
  if (action1 !== undefined) {
    g("actions").appendChild(t(`<a id="action1" href="${action1}" class=""><img src="https://firebasestorage.googleapis.com/v0/b/kudu-ride.appspot.com/o/1_system%2FDownload_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg?alt=media&token=9ae007a6-18b2-42f1-aae0-0878fe74c477&_gl=1*gyyk57*_ga*MTQwNDM4Mjc1MC4xNjc5NDg4OTI3*_ga_CW55HF8NVT*MTY4NjMzMTMyMC4xMTYuMS4xNjg2MzMzNTk0LjAuMC4w" alt="" width="160"></a>`))
  }

  const action2 = configurationData.hero.action2
  if (action2 !== undefined) {
    g("actions").appendChild(t(`<a id="action2" href="${action2}" class=""><img src="https://firebasestorage.googleapis.com/v0/b/kudu-ride.appspot.com/o/1_system%2Fimage%201.png?alt=media&token=4ac897e0-1a52-4c20-8839-0c78f9a05d54&_gl=1*10nm3ug*_ga*MTQwNDM4Mjc1MC4xNjc5NDg4OTI3*_ga_CW55HF8NVT*MTY4NjU2MDY1MC4xMjguMS4xNjg2NTcwMzk1LjAuMC4w" alt="" width="180"></a>`))
  }

  g("img1").src = configurationData.hero["img1"]
  g("img2").src = configurationData.hero["img2"]
  g("img3").src = configurationData.hero["img3"]

  const features = configurationData.features
  features.forEach(feature => {
    g("features").appendChild(t(`<li class="col-6@md">
        <div class="flex gap-sm items-center">
          <figure class="flex-shrink-0 inline-flex flex-center width-lg height-lg radius-50% inner-glow shadow-ring bg-light">
            ${feature.icon.trim()}
          </figure>

          <h4>${feature.name}</h4>
        </div>

        <div class="flex gap-sm">
          <div class="flex-shrink-0 width-lg" aria-hidden="true"></div>

          <p class="color-contrast-medium line-height-lg">${feature.description}</p>
        </div>
      </li>`)
    )
  });

  const howItWorks = configurationData["howItWorks"]
  howItWorks.forEach(element => {
    if (element.hasAction) {
      g("hiw-list").appendChild(t(`<li class="hiw-list__item">
        <div class="hiw-list__item-inner">
          <div class="hiw-list__counter" aria-hidden="true"></div>

          <div class="flex-grow">
            <div class="hiw-list__content grid gap-md items-center@md">
              <figure class="col-6@md">
                <img class="block width-100%" src="${element.figure}"
                  alt="Image description">
              </figure>

              <div class="col-6@md">
                <div class="text-component">
                  <h2 class="text-lg">${element.title}</h2>
                  <p class="color-contrast-medium">${element.description}</p>
                </div>
                <div class="margin-top-md">
                  <a class="btn btn--primary" href="${element.action}">${element.actionTitle}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>`)
      )
    } else {
      g("hiw-list").appendChild(t(`<li class="hiw-list__item">
        <div class="hiw-list__item-inner">
          <div class="hiw-list__counter" aria-hidden="true"></div>

          <div class="flex-grow">
            <div class="hiw-list__content grid gap-md items-center@md">
              <figure class="col-6@md">
                <img class="block width-100%" src="${element.figure}" alt="Image description">
              </figure>

              <div class="col-6@md">
                <div class="text-component">
                  <h2 class="text-lg">${element.title}</h2>
                  <p class="color-contrast-medium">${element.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>`)
      )
    }
  });


  if (!configurationData.credit) {
    g("credit").style.display = "none"
  }

  socials(configurationData.socials, "socials")
}

require('./_assets/v2/js/scripts')